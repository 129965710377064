import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';

import FurloughForm from '../../components/furlough-calculator/FurloughForm';
import TipJarContent from '../../components/furlough-calculator/TipJarContent';
import Sponsors from '../../components/furlough-calculator/Sponsors';
import Collaboration from '../../components/furlough-calculator/Collaboration';

import { ViewportBreakpoints } from '../../components/cssConstants';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 60px;
  }
`;

const InnerContainer = styled.div`
  max-width: 800px;
  text-align: center;
`;

const Page = ({ data }) => {
  useEffect(() => {
    Intercom('update', {
      hide_default_launcher: true,
    });
    return () => {
      Intercom('update', {
        hide_default_launcher: false,
      });
    };
  }, []);

  return (
    <Body displayHeader={false} displayFooter={false}>
      <SEO
        title="Furlough calculator"
        description="Rotaready & TiPJAR have teamed up to develop a furlough calculator for employees. So you can work out what you'll be paid, even if your monthly pay varies."
        url="furlough-calculator"
      />
      <Container>
        <InnerContainer>
          <Collaboration data={data} />
          <FurloughForm />
        </InnerContainer>
      </Container>
      <TipJarContent data={data} />
      <Sponsors data={data} />
    </Body>
  );
};


export default Page;

export const query = graphql`
  query {
    tipjarLogo: file(relativePath: { eq: "misc/tipjar-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    tipjarImage: file(relativePath: { eq: "misc/tipjar-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 75
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }
  }
`;
