const FURLOUGHED = 'FURLOUGHED';
const NOT_FURLOUGHED = 'NOT_FURLOUGHED';

const FURLOUGH_OPTIONS = [{
  label: 'Yes',
  value: FURLOUGHED,
}, {
  label: 'No, but I want to understand the impact on me in case I am',
  value: NOT_FURLOUGHED,
}];

const EMPLOYED_SINCE_FEB = 'EMPLOYED_SINCE_FEB';
const NOT_EMPLOYED_SINCE_FEB = 'NOT_EMPLOYED_SINCE_FEB';

const EMPLOYED_FEB_OPTIONS = [{
  label: 'Yes',
  value: EMPLOYED_SINCE_FEB,
}, {
  label: 'No',
  value: NOT_EMPLOYED_SINCE_FEB,
}];

const VARIED_PAY_WEEKLY = 'WEEKLY';
const VARIED_PAY_FORTNIGHTLY = 'FORTNIGHTLY';
const VARIED_PAY_MONTHLY = 'MONTHLY';

const VARIED_PAY_OPTIONS = [{
  label: 'Weekly',
  value: VARIED_PAY_WEEKLY,
}, {
  label: 'Fortnightly',
  value: VARIED_PAY_FORTNIGHTLY,
}, {
  label: 'Monthly',
  value: VARIED_PAY_MONTHLY,
}];

const START_JOB_OPTIONS = [{
  label: 'February 2019', value: '2019-02-01',
}, {
  label: 'March 2019', value: '2019-03-01',
}, {
  label: 'April 2019', value: '2019-04-01',
}, {
  label: 'May 2019', value: '2019-05-01',
}, {
  label: 'June 2019', value: '2019-06-01',
}, {
  label: 'July 2019', value: '2019-07-01',
}, {
  label: 'August 2019', value: '2019-08-01',
}, {
  label: 'September 2019', value: '2019-09-01',
}, {
  label: 'October 2019', value: '2019-10-01',
}, {
  label: 'November 2019', value: '2019-11-01',
}, {
  label: 'December 2019', value: '2019-12-01',
}, {
  label: 'January 2020', value: '2019-01-01',
}, {
  label: 'February 2020', value: '2020-02-01',
}];

const PAYSLIPS = ['', '', '', '', '', ''];

const NET_PAY = 'NET_PAY';
const GROSS_PAY = 'GROSS_PAY';

const GROSS_OR_NET_OPTIONS = [{
  label: "Net pay (the amount I've received into my bank account)",
  value: NET_PAY,
}, {
  label: 'Gross pay (before the normal deductions apply like tax, National Insurance)',
  value: GROSS_PAY,
}];

const ANNUAL_SALARY_YES = 'ANNUAL_SALARY_YES';
const ANNUAL_SALARY_NO = 'ANNUAL_SALARY_NO';

const ANNUAL_SALARY_OPTIONS = [{
  label: 'Yes',
  value: ANNUAL_SALARY_YES,
}, {
  label: 'No',
  value: ANNUAL_SALARY_NO,
}];

export {
  FURLOUGHED,
  NOT_FURLOUGHED,
  FURLOUGH_OPTIONS,
  EMPLOYED_SINCE_FEB,
  NOT_EMPLOYED_SINCE_FEB,
  EMPLOYED_FEB_OPTIONS,
  VARIED_PAY_WEEKLY,
  VARIED_PAY_FORTNIGHTLY,
  VARIED_PAY_MONTHLY,
  VARIED_PAY_OPTIONS,
  START_JOB_OPTIONS,
  PAYSLIPS,
  NET_PAY,
  GROSS_PAY,
  GROSS_OR_NET_OPTIONS,
  ANNUAL_SALARY_OPTIONS,
  ANNUAL_SALARY_YES,
  ANNUAL_SALARY_NO,
};
