import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Button from '@rotaready/frecl/build/Button';

import { ViewportBreakpoints } from '../cssConstants';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerContent = styled.div`
  max-width: 1600px;
  margin-top: 40px;
  display: flex;
  flex-direction: column-reverse;
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row-reverse;
    position: relative;
    margin-top: 80px;
    margin-left: 120px;
    margin-right: 120px;
  }
`;

const TipJarHeroImage = styled.div`
  position: relative;
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 588px;
    width: 50%;
    margin-left: auto;
  }
`;

const TipJarContentContainer = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 40%;
  }
`;

const TipJarBodyText = styled.p`
  font-family: Rubik;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 30px;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const TipJarTextContent = styled.div`
  margin: 40px 0;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
  }

  ${TipJarBodyText}:last-of-type {
    margin-bottom: 40px;
  }
`;

const ButtonContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 200px;
  }
`;

const TipJarHeroText = styled.h3`
  font-family: Rubik;
  font-size: 38px;
  line-height: 54px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 20px;
  color: ${({ theme: { colors } }) => colors.brand160};
`;

const OverlayedLogo = styled.div`
  height: 45px;
  width: 99px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: white;
  padding: 10px 13px;
  border-radius: 3.6px;
  box-shadow: 0 0 10px 2px rgba(71, 91, 104, 0.1);
  border: solid 1.2px ${({ theme: { colors } }) => colors.grey20};
`;

const StyledLinkText = styled.a`
  color: white;
  text-decoration: none;
`;

const TipJarContent = ({ data }) => (
  <Content>
    <InnerContent>
      <TipJarHeroImage>
        <GatsbyImage
          image={data.tipjarImage.childImageSharp.gatsbyImageData}
          style={{ height: '100%', maxWidth: '560px' }}
          alt="Tipjar"
        />
        <OverlayedLogo>
          <GatsbyImage
            image={data.tipjarLogo.childImageSharp.gatsbyImageData}
            style={{ maxWidth: '143px' }}
            alt="Tipjar logo"
          />
        </OverlayedLogo>
      </TipJarHeroImage>
      <TipJarContentContainer>
        <TipJarTextContent>
          <TipJarHeroText>
            Hospitality Workers Emergency Fund
          </TipJarHeroText>
          <TipJarBodyText>
            TiPJAR have teamed up with Hospitality Action to allow the UK public to donate to an emergency fund to help the most vulnerable and in need in the hospitality sector during this time.
          </TipJarBodyText>
          <TipJarBodyText>
            Using funds raised via the TiPJAR platform, Hospitality Action will work 24/7 to identify those who need help the most, and give them a grant of £250. If you can, please donate to support hospitality workers in need in your local city. Every pound will help.
          </TipJarBodyText>
          <TipJarBodyText>
            This is a not-for-profit venture powered by TiPJAR in collaboration with Hospitality Action.
          </TipJarBodyText>
          <ButtonContainer>
            <Button><StyledLinkText href="https://www.wearetipjar.com/donate/" target="_blank">Donate here</StyledLinkText></Button>
          </ButtonContainer>
        </TipJarTextContent>
      </TipJarContentContainer>
    </InnerContent>
  </Content>
);

export default TipJarContent;
