import {
  VARIED_PAY_WEEKLY,
  VARIED_PAY_FORTNIGHTLY,
  VARIED_PAY_MONTHLY,
} from './constants';

const GROSS_BANDS_SALARY = [
  15000,
  17500,
  20000,
  22500,
  25000,
  27500,
  30000,
  32500,
  35000,
  37500,
];
const GROSS_BANDS_MONTHLY = [
  1250,
  1450,
  1667,
  1875,
  2080,
  2290,
  2500,
  2700,
  2915,
  3125,
];
const NET_BANDS_MONTHLY = [
  1150,
  1295,
  1430,
  1575,
  1720,
  1860,
  2000,
  2145,
  2285,
  2425,
];

const GROSS_NET_BANDS_PERCENTAGE = [
  92.00,
  89.31,
  85.78,
  84.00,
  82.69,
  81.22,
  80.00,
  79.44,
  78.39,
  77.60,
];

const MONTHLY_NET_CAP = 2500;
const PERCENTAGE_CAP = 0.8;

const findMatch = (band, goal) => {
  let indexMatch = 0;
  band.reduce((prev, curr, idx) => {
    if (Math.abs(curr - goal) < Math.abs(prev - goal)) {
      indexMatch = idx;
      return curr;
    }
    return prev;
  });

  return indexMatch;
};

const calculateValuesOnGrossPay = (averageMonthlySalary, payFrequency) => {
  const maxAveMonthlyGrossSalary = Math.min((averageMonthlySalary * PERCENTAGE_CAP), MONTHLY_NET_CAP);
  const index = findMatch(GROSS_BANDS_MONTHLY, maxAveMonthlyGrossSalary);
  const matchedGrossAveragePercentage = (GROSS_NET_BANDS_PERCENTAGE[index] / 100);
  
  switch (payFrequency) {
  case VARIED_PAY_WEEKLY: {
    const grossSalaryAdjustedForFrequency = ((maxAveMonthlyGrossSalary * 12) / 52) * 1;
  
    return {
      gross: grossSalaryAdjustedForFrequency,
      net: grossSalaryAdjustedForFrequency * matchedGrossAveragePercentage,
    };
  }
  case VARIED_PAY_FORTNIGHTLY: {
    const grossSalaryAdjustedForFrequency = ((maxAveMonthlyGrossSalary * 12) / 52) * 2;
  
    return {
      gross: grossSalaryAdjustedForFrequency,
      net: grossSalaryAdjustedForFrequency * matchedGrossAveragePercentage,
    };
  }
  case VARIED_PAY_MONTHLY: {
    return {
      gross: maxAveMonthlyGrossSalary,
      net: maxAveMonthlyGrossSalary * matchedGrossAveragePercentage,
    };
  }
        
  default:
    break;
  }
};

const calculateValuesOnNetPay = (averageMonthlySalary, payFrequency) => {
  const netIndex = findMatch(NET_BANDS_MONTHLY, averageMonthlySalary);
  const maxAveMonthlyGrossSalaryNet = Math.min(MONTHLY_NET_CAP, (GROSS_BANDS_MONTHLY[netIndex] * PERCENTAGE_CAP));
  const grossIndex = findMatch(GROSS_BANDS_MONTHLY, maxAveMonthlyGrossSalaryNet);
  const matchedNetAveragePercentageX = (GROSS_NET_BANDS_PERCENTAGE[grossIndex] / 100);

  switch (payFrequency) {
  case VARIED_PAY_WEEKLY: {
    const grossSalaryAdjustedForFrequency = ((maxAveMonthlyGrossSalaryNet * 12) / 52) * 1;

    return {
      gross: grossSalaryAdjustedForFrequency,
      net: grossSalaryAdjustedForFrequency * matchedNetAveragePercentageX,
    };
  }
  case VARIED_PAY_FORTNIGHTLY: {
    const grossSalaryAdjustedForFrequency = ((maxAveMonthlyGrossSalaryNet * 12) / 52) * 2;

    return {
      gross: grossSalaryAdjustedForFrequency,
      net: grossSalaryAdjustedForFrequency * matchedNetAveragePercentageX,
    };
  }
  case VARIED_PAY_MONTHLY: {
    return {
      gross: maxAveMonthlyGrossSalaryNet,
      net: maxAveMonthlyGrossSalaryNet * matchedNetAveragePercentageX,
    };
  }
      
  default:
    break;
  }
};

const calculateValuesOnSalaryPay = (annualSalary) => {
  const maxMonthlySalary = Math.min(((annualSalary * PERCENTAGE_CAP) / 12), MONTHLY_NET_CAP);
  const index = findMatch(GROSS_BANDS_SALARY, maxMonthlySalary * 12);
  const matchedGrossSalaryPercentage = (GROSS_NET_BANDS_PERCENTAGE[index] / 100);

  return {
    gross: maxMonthlySalary,
    net: maxMonthlySalary * matchedGrossSalaryPercentage,
  };
};

const calculateFortnightlyAverage = (payslips) => {
  const total = payslips.reduce((prev, curr) => prev + curr, 0);
  const weeks = payslips.length * 2;
  return total ? ((52 / weeks) * total) / 12 : 0;
};

const calculateWeeklyAverage = (payslips) => {
  const total = payslips.reduce((prev, curr) => prev + curr, 0);
  const weeks = payslips.length;
  return total ? ((52 / weeks) * total) / 12 : 0;
};

const calculateMonthlyAverage = (payslips) => {
  const total = payslips.reduce((prev, curr) => prev + curr, 0);
  const months = payslips.length;
  return total ? total / months : 0;
};

export {
  MONTHLY_NET_CAP,
  PERCENTAGE_CAP,
  GROSS_BANDS_SALARY,
  GROSS_BANDS_MONTHLY,
  NET_BANDS_MONTHLY,
  GROSS_NET_BANDS_PERCENTAGE,
  findMatch,
  calculateValuesOnGrossPay,
  calculateValuesOnNetPay,
  calculateValuesOnSalaryPay,
  calculateFortnightlyAverage,
  calculateWeeklyAverage,
  calculateMonthlyAverage,
};
