/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import WarningNotification from '@rotaready/frecl/build/WarningNotification';
import AlertNotification from '@rotaready/frecl/build/AlertNotification';
import RadioButtonGroup from '@rotaready/frecl/build/RadioButtonGroup';
import FormInputWrapper from '@rotaready/frecl/build/FormInputWrapper';
import NumberInput from '@rotaready/frecl/build/NumberInput';
import InputSideText from '@rotaready/frecl/build/InputSideText';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import { RowV2 } from '@rotaready/frecl/build/Row';
import { ColumnV2 } from '@rotaready/frecl/build/Column';

import { Formik, FieldArray } from 'formik';

import {
  FURLOUGH_OPTIONS,
  EMPLOYED_SINCE_FEB,
  NOT_EMPLOYED_SINCE_FEB,
  EMPLOYED_FEB_OPTIONS,
  VARIED_PAY_WEEKLY,
  VARIED_PAY_FORTNIGHTLY,
  VARIED_PAY_MONTHLY,
  VARIED_PAY_OPTIONS,
  PAYSLIPS,
  GROSS_PAY,
  GROSS_OR_NET_OPTIONS,
  NOT_FURLOUGHED,
  FURLOUGHED,
  ANNUAL_SALARY_OPTIONS,
  ANNUAL_SALARY_YES,
  ANNUAL_SALARY_NO,
} from './constants';
import { ViewportBreakpoints } from '../cssConstants';

import {
  calculateValuesOnGrossPay,
  calculateValuesOnNetPay,
  calculateValuesOnSalaryPay,
  calculateFortnightlyAverage,
  calculateWeeklyAverage,
  calculateMonthlyAverage,
} from './calculator';


const QuestionContainer = styled.div`
  margin-top: 40px;
`;

const InnerContainer = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.white};
  text-align: left;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding-left: 80px;
    padding-right: 80px;
    box-shadow: 0 0 8px 2px ${({ theme: { colors } }) => colors.grey20};
    border: solid 1px ${({ theme: { colors } }) => colors.grey20};
  }
  ${QuestionContainer}:last-of-type {
    margin-bottom: 20px;
  }
`;

const WarningContainer = styled.div`
  max-width: 540px;
`;


const ResultsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const ResultsRow = styled(RowV2)`
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.grey40};
`;

const PaySlipContainer = styled.div`
  margin-top: 20px;
`;

const StyledForm = styled.form`
  max-width: 570px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding-left: 80px;
    padding-right: 80px;
  }
`;

const ResultsText = styled(Text)`
  padding-bottom: 20px;
`;

const DisclaimerText = styled(Text)`
  padding-top: 10px;
`;

const emptyStringToZero = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

const schema = yup.object().shape({
  annualSalary: yup.number().min(0).notRequired().default(0)
    .transform(emptyStringToZero),
  payslips: yup.array().of(yup.number().min(0).notRequired().nullable()
    .transform(emptyStringToZero)),
});

const FurloughForm = () => {
  const [furloughedNet, setFurloughedNet] = useState(0);
  const [furloughedGross, setFurloughedGross] = useState(0);

  return (
    <InnerContainer>
      <WarningContainer>
        <WarningNotification text="Anything you tell us will be kept completely private and won't be shared with anyone, not even Rotaready or TiPJAR." />
      </WarningContainer>

      <Formik
        initialValues={{
          furlough: null,
          employedFeb: null,
          payslips: PAYSLIPS,
          annualSalary: '',
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          const {
            payslips, variedPay, grossOrNet, annualSalary, annualSalaryYesNo,
          } = schema.cast(values);

          const trimmedPayslips = [];
          payslips.forEach(payslip => payslip && trimmedPayslips.push(payslip));

          if (annualSalaryYesNo === ANNUAL_SALARY_YES) {
            const { gross, net } = calculateValuesOnSalaryPay(annualSalary);

            setFurloughedGross(gross);
            setFurloughedNet(net);
            return;
          }

          switch (variedPay) {
          case VARIED_PAY_FORTNIGHTLY: {
            const averageMonthlySalary = calculateFortnightlyAverage(trimmedPayslips);

            if (grossOrNet === GROSS_PAY) {
              const {
                gross,
                net,
              } = calculateValuesOnGrossPay(averageMonthlySalary, variedPay);

              setFurloughedGross(gross);
              setFurloughedNet(net);
            } else {
              const { gross, net } = calculateValuesOnNetPay(averageMonthlySalary, variedPay);
              setFurloughedGross(gross);
              setFurloughedNet(net);
            }
            break;
          }

          case VARIED_PAY_WEEKLY: {
            const averageMonthlySalary = calculateWeeklyAverage(trimmedPayslips);

            if (grossOrNet === GROSS_PAY) {
              const {
                gross,
                net,
              } = calculateValuesOnGrossPay(averageMonthlySalary, variedPay);

              setFurloughedGross(gross);
              setFurloughedNet(net);
            } else {
              const { gross, net } = calculateValuesOnNetPay(averageMonthlySalary, variedPay);
              setFurloughedGross(gross);
              setFurloughedNet(net);
            }

            break;
          }

          case VARIED_PAY_MONTHLY: {
            const averageMonthlySalary = calculateMonthlyAverage(trimmedPayslips);

            if (grossOrNet === GROSS_PAY) {
              const {
                gross,
                net,
              } = calculateValuesOnGrossPay(averageMonthlySalary, variedPay);

              setFurloughedGross(gross);
              setFurloughedNet(net);
            } else {
              const { gross, net } = calculateValuesOnNetPay(averageMonthlySalary, variedPay);
              setFurloughedGross(gross);
              setFurloughedNet(net);
            }

            break;
          }
          default:
            break;
          }
          const results = document.getElementById('rr-furlough-results');
          results.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          submitCount,
          resetForm,
        }) => (
          <StyledForm onSubmit={handleSubmit}>
            <QuestionContainer>
              <FormInputWrapper label="Have you been furloughed?" fitsContainer>
                <RadioButtonGroup
                  name="furlough"
                  options={FURLOUGH_OPTIONS}
                  value={values.furlough}
                  onChange={(value) => {
                    setFieldValue('furlough', value);
                    setFieldValue('employedFeb', null);
                  }}
                />
              </FormInputWrapper>
            </QuestionContainer>
            {values.furlough === NOT_FURLOUGHED
              ? (
                <QuestionContainer>
                  <FormInputWrapper label="Were you employed on 19 Mar 2020?" fitsContainer compact>
                    <RadioButtonGroup
                      name="furlough"
                      options={EMPLOYED_FEB_OPTIONS}
                      value={values.employedFeb}
                      onChange={(value) => {
                        setFieldValue('employedFeb', value);
                      }}
                    />
                  </FormInputWrapper>
                  {values.employedFeb === EMPLOYED_SINCE_FEB && <Text text="You should be eligible for furlough. This applies even if you're on a zero-hours contract." />}
                </QuestionContainer>
              )
              : <span />}

            {values.employedFeb === NOT_EMPLOYED_SINCE_FEB
              ? <AlertNotification text="It's unlikely you'll be eligible for furlough. You should check with your employer" />
              : (values.furlough === FURLOUGHED || values.employedFeb) && (
                <>
                  <QuestionContainer>
                    <FormInputWrapper label="Do you have an annual salary?" fitsContainer>
                      <RadioButtonGroup
                        name="furlough"
                        options={ANNUAL_SALARY_OPTIONS}
                        value={values.annualSalaryYesNo}
                        onChange={(value) => {
                          setFieldValue('annualSalaryYesNo', value);
                          resetForm({
                            values: {
                              furlough: values.furlough,
                              employedFeb: values.employedFeb,
                              payslips: PAYSLIPS,
                              grossOrNet: values.grossOrNet,
                              annualSalaryYesNo: value,
                            },
                          });
                        }}
                      />
                    </FormInputWrapper>
                  </QuestionContainer>
                  {values.annualSalaryYesNo ? values.annualSalaryYesNo === ANNUAL_SALARY_NO ? (
                    <QuestionContainer>
                      <FormInputWrapper label="How often do you get paid?" fitsContainer>
                        <RadioButtonGroup
                          name="furlough"
                          options={VARIED_PAY_OPTIONS}
                          value={values.variedPay}
                          onChange={(value) => {
                            setFieldValue('variedPay', value);
                            resetForm({
                              values: {
                                furlough: values.furlough,
                                employedFeb: values.employedFeb,
                                payslips: PAYSLIPS,
                                grossOrNet: values.grossOrNet,
                                annualSalaryYesNo: values.annualSalaryYesNo,
                                variedPay: value,
                              },
                            });
                          }}
                        />
                      </FormInputWrapper>
                    </QuestionContainer>
                  ) : (
                    <QuestionContainer>
                      <FormInputWrapper label="What's your current annual salary" fitsContainer>
                        <NumberInput
                          name="annualSalary"
                          value={values.annualSalary}
                          onChange={({ target: { value } }) => setFieldValue('annualSalary', value)}
                          inputRef={React.createRef()}
                          placeholder="00000"
                          leftItem={<InputSideText text="£" weight="normal" />}
                          leftPadding="0px"
                          min="0"
                          hasTitle
                        />
                      </FormInputWrapper>
                    </QuestionContainer>
                  ) : <span />}

                  {values.annualSalaryYesNo === ANNUAL_SALARY_NO
                    ? (
                      <>
                        <QuestionContainer>
                          <FormInputWrapper label="Do you have figures for Gross or Net pay?" fitsContainer>
                            <RadioButtonGroup
                              name="grossOrNet"
                              options={GROSS_OR_NET_OPTIONS}
                              value={values.grossOrNet}
                              onChange={(value) => {
                                setFieldValue('grossOrNet', value);
                                resetForm({
                                  values: {
                                    furlough: values.furlough,
                                    employedFeb: values.employedFeb,
                                    payslips: PAYSLIPS,
                                    grossOrNet: value,
                                    variedPay: values.variedPay,
                                    annualSalaryYesNo: values.annualSalaryYesNo,
                                  },
                                });
                              }}
                            />
                          </FormInputWrapper>
                        </QuestionContainer>
                        {values.grossOrNet
                          ? (
                            <QuestionContainer>
                              <Text text="Enter as many payslips as you can. We recommend at least 3 to give you an accurate result." />
                              <PaySlipContainer>
                                <FieldArray
                                  name="payslips"
                                  render={() => (
                                    values.payslips && values.payslips.length > 0
                                      ? (
                                        values.payslips.map((pay, index) => (
                                          <FormInputWrapper label={`Payslip ${index + 1}`} width={400} compact key={index}>
                                            <NumberInput
                                              name={`payslips.${index}`}
                                              value={pay}
                                              onChange={({ target: { value } }) => setFieldValue(`payslips.${index}`, value)}
                                              inputRef={React.createRef()}
                                              placeholder="000"
                                              leftItem={<InputSideText text="£" weight="normal" />}
                                              leftPadding="0px"
                                              min="0"
                                              step={0.01}
                                              hasTitle
                                            />
                                          </FormInputWrapper>
                                        )))
                                      : <span />
                                  )}
                                />
                              </PaySlipContainer>
                              <Text size="sm" uistlye="secondary" text="The Government have confirmed that Tronc payments are excluded from the furlough scheme, so please provide figures that exclude Tronc. We are monitoring the situation in case this changes." />
                            </QuestionContainer>
                          )
                          : <span />
                        }
                      </>
                    )
                    : <span />
                  }
                  {values.annualSalaryYesNo === ANNUAL_SALARY_YES || (values.variedPay && values.grossOrNet)
                    ? (
                      <QuestionContainer>
                        <Button text="See estimated furlough pay" type="submit" />
                      </QuestionContainer>
                    )
                    : <span />
                  }

                  {submitCount > 0
                && (
                  <ResultsContainer id="rr-furlough-results">
                    <ResultsText size="xxl" text={`Your furlough ${values.variedPay === VARIED_PAY_MONTHLY || values.annualSalaryYesNo === ANNUAL_SALARY_YES ? 'monthly' : values.variedPay === VARIED_PAY_FORTNIGHTLY ? 'fortnightly' : 'weekly'} pay will be approximately...`} uistyle="brand160" />
                    <ResultsRow>
                      <ColumnV2>
                        <Text size="lg" text="Gross (before tax): " />
                      </ColumnV2>
                      <ColumnV2 alignItems="flex-end">
                        <Text size="lg" text={`£${(Math.round((furloughedGross) * 100) / 100).toFixed(2)}`} uistyle="brand160" weight={600} />
                      </ColumnV2>
                    </ResultsRow>
                    <ResultsRow>
                      <ColumnV2>
                        <Text size="lg" text="Net (after tax): " />
                      </ColumnV2>
                      <ColumnV2 alignItems="flex-end">
                        <Text size="lg" text={`£${(Math.round((furloughedNet) * 100) / 100).toFixed(2)}`} uistyle="brand160" weight={600} />
                      </ColumnV2>
                    </ResultsRow>
                    <DisclaimerText size="sm" uistlye="secondary" text="We cannot guarantee the accuracy of this result as the exact amount you will be paid will be based on your personal situation, your employer's decisions, and any changes the government makes to the furlough rules." />
                  </ResultsContainer>
                )}
                </>
              )
            }
          </StyledForm>
        )}
      </Formik>
    </InnerContainer>
  );
};

export default FurloughForm;
