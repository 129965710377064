import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Text from '@rotaready/frecl/build/Text';
import H2 from '@rotaready/frecl/build/H2';
import Logo from '@rotaready/frecl/build/Logo';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

import { ViewportBreakpoints } from '../cssConstants';

const SponserSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 120px;
    margin-left: 140px;
    margin-right: 140px;
    margin-bottom: 80px;
  }
`;

const SmallLogoContainer = styled.div`
  svg {
    width: auto;
    height: 45px;
  }
`;

const SponserBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-left: 10px;
  margin-right: 10px;

  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;

  border-top: solid 4px ${({ theme: { colors }, tipjar }) => tipjar ? '#00ffcc' : colors.brand};
  background-color: ${({ theme: { colors } }) => colors.white};
  align-self: stretch;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 0 calc(50% - 40px);
    padding-bottom: 40px;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletLandscapeUpper}) {
    flex: 1 0 calc(50% - 40px);
    padding-bottom: 40px;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }

  p:last-of-type {
    margin-bottom: 20px;
  }
`;

const SponserBoxContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 1600px;

  ${SponserBox}:first-of-type {
    margin-bottom: 40px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;

    ${SponserBox}:first-of-type {
      margin-bottom: 0px;
    }
  }
`;

const SponsorText = styled(Text)`
  margin-top: 20px;
  line-height: 22px;
`;


const SponserBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  box-sizing: border-box;
  text-align: left;
  align-self: stretch;
  justify-content: space-between;
  flex: 1 0 auto;
`;

const Sponsors = ({ data }) => (
  <SponserSection>
    <H2 uistyle="brand160" text="About the sponsors" />
    <SponserBoxContainer>
      <SponserBox>
        <SponserBoxContent>
          <SmallLogoContainer>
            <Logo uistyle="primary" />
          </SmallLogoContainer>
          <SponsorText size="sm" text="Hospitality and retail's answer to effortless staff scheduling and wage management. Before Rotaready, we worked in pubs, bars, hotels, shops and theme parks. And managing rotas, holiday accrual and timesheets was our least favourite thing." />
          <SponsorText size="sm" text="We believed there must be a better way of doing things, so we built Rotaready. A solution that enables you to build rotas automatically, manage costs, integrates out-of-the-box with other systems and crucially, is fun to use." />
          <LinkWithArrow
            direction="right"
            text="Visit their website"
            render={props => <a {...props} href="https://rotaready.com" target="_blank" />}
          />
        </SponserBoxContent>
      </SponserBox>
      <SponserBox tipjar>
        <SponserBoxContent>
          <div style={{ height: '45px', width: '99px' }}>
            <GatsbyImage
              image={data.tipjarLogo.childImageSharp.gatsbyImageData}
              style={{ maxWidth: '143px' }}
              alt="Tipjar logo"
            />
          </div>
          <SponsorText size="sm" text="Built by hospitality workers for hospitality workers, TiPJAR is the new way to tip. We set out to revolutionise the world of tipping by giving power to the workers, whilst still helping businesses & managers with the current pain point of handling card payment tips." />
          <SponsorText size="sm" text="With TiPJAR, tipped workers can receive tips via QR codes, NFC and contactless and share these tips with other workers within the business. Making it easy to receive & manage tips, both directly & transparently." />
          <LinkWithArrow
            direction="right"
            text="Visit their website"
            render={props => <a {...props} href="https://www.wearetipjar.com/" target="_blank" />}
          />
        </SponserBoxContent>
      </SponserBox>
    </SponserBoxContainer>
  </SponserSection>
);

export default Sponsors;
