import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';

import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';
import { RowV2 }  from '@rotaready/frecl/build/Row';
import { ColumnV2 } from '@rotaready/frecl/build/Column';
import Logo from '@rotaready/frecl/build/Logo';

import { ViewportBreakpoints } from '../cssConstants';

const StyledCollaborationCross = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'times'],
})`
  font-size: 20px;
  color: ${({ theme: { colors } }) => colors.grey40};
`;

const LogoContainer = styled.a`
  svg {
    width: auto;
    height: 40px;
  }
`;

const HeroTextContainer = styled.div`
  margin-bottom: 40px;
`;

const BroughtToYouContainer = styled.div`
  margin-top: 40px;
`;

const BodyText = styled(H4)`
  margin-bottom: 20px;
`;

const AboveTheFoldContentContainer = styled.div`
  margin-bottom: 40px;
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
  margin-bottom: 80px;
  }
`;

const LogoRow = styled(RowV2)`
  margin-top: 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    flex-wrap: wrap;
    height: 65px;
  }
`;

const LogoColumn = styled(ColumnV2)`
  flex-shrink: 1;
  flex-grow: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-grow: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const TipJarLogoContainer = styled.a`
  height: 65px;
  width: 143px;
`;

const Collaboration = ({ data }) => (
  <AboveTheFoldContentContainer>
    <HeroTextContainer>
      <H1 text="Furloughed?" uistyle="brand160" />
      <H1 text="Work out how much you're owed" uistyle="brand160" />
    </HeroTextContainer>
    <BodyText uistyle="secondary" text="If you've been furloughed, getting your head around what your next paycheck will be is tricky. Especially if you work varied hours." />
    <BodyText uistyle="secondary" text="That's why we've teamed up with TiPJAR to develop a furlough calculator. So you can work out what you'll be paid, even if your monthly pay varies. Just provide a few details and we'll give you a good idea of what you'll get." />
    <BroughtToYouContainer>
      <H4 uistyle="brand160" weight={600} text="Brought to you by" />
    </BroughtToYouContainer>
    <LogoRow gutter={20}>
      <LogoColumn>
        <LogoContainer href="https://rotaready.com" target="_blank">
          <Logo uistyle="primary" />
        </LogoContainer>
      </LogoColumn>
      <LogoColumn>
        <StyledCollaborationCross />
      </LogoColumn>
      <LogoColumn>
        <TipJarLogoContainer href="https://www.wearetipjar.com/" target="_blank">
          <GatsbyImage
            image={data.tipjarLogo.childImageSharp.gatsbyImageData}
            style={{ maxWidth: '143px' }}
            alt="Tipjar logo"
          />
        </TipJarLogoContainer>
      </LogoColumn>
    </LogoRow>
  </AboveTheFoldContentContainer>
);

export default Collaboration;
